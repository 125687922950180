<template>
  <div class="container" style="padding: 10px">
    <div class="columns is-centered">
      <div class="column is-12">
        <img src="../../assets/logo.svg">
      </div>
    </div>
    <div class="columns is-centered">
      <a @click="$ml.change('ru')" title="Русский" style="margin-right: 5px">
        <img src="../../assets/ru.png">
      </a>
      <a @click="$ml.change('en')" title="English">
        <img src="../../assets/en.png">
      </a>
    </div>
    <form autocomplete="off" @submit.prevent="login" novalidate>
      <b-loading :is-full-page="false" v-model="formLoading" :can-cancel="false"></b-loading>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field label="Email" label-position="on-border"
                   :message="'email' in errors ? errors['email'] : ''"
                   :type="'email' in errors ? 'is-danger' : ''">
            <b-input autocomplete="nope" type="text" v-model="form.email" icon="email"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field :label="$ml.get('login_password')" label-position="on-border"
                   :message="'password' in errors ? errors['password'] : $ml.get('newpass_new_password_message')"
                   :type="'password' in errors ? 'is-danger' : ''">
            <b-input autocomplete="nope" type="password" v-model="form.password" icon="lock"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <vue-recaptcha ref="recaptcha" sitekey="6LdqpCUcAAAAAMUeYmQTFuK7Z9CBrzqVwy9sC9h_" @verify="onVerify" :loadRecaptchaScript="true">
          </vue-recaptcha>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <ul class="horizontal">
            <li>
              <b-button :disabled="!submitEnabled" type="is-info" native-type="submit">
                {{ $ml.get('signup_do_signup') }}
              </b-button>
            </li>
            <li style="padding: 10px 5px 0 80px">
              <router-link :to="{ path: `/login` }">{{ $ml.get('login_login') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import Vue from "vue";
import VueRecaptcha from 'vue-recaptcha'

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        ref_code: '',
      },
      errors: {},
      formLoading: false,
      captcha: false,
    }
  },
  components: {VueRecaptcha},
  computed: {
    submitEnabled() {
      return this.form.email.length >= 5 && this.form.password.length >= 6 && this.captcha
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) this.captcha = true;
    },
    login: function () {
      this.formLoading = true
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'auth/signup', {user: this.form}).then(resp => {
        router.push('/login?c=t');
      }).catch(error => {
        this.errors = error.response.data.result
      }).finally(() => {
        this.formLoading = false
      })
    },
  },
  mounted() {
    let ref = this.$route.query.rc || localStorage.getItem('ref')
    if (ref) {
      localStorage.setItem('ref', ref)
      this.form.ref_code = ref
      console.log(ref);
    }
  }
}
</script>

<style scoped>
img {
  max-width: 300px;
}
</style>